<template>
  <df-modal
    persistent
    width="880px"
    :action-name="$t('SowingsPlanning.copy_sowing_modal.copy')"
    :loading="loading"
    :title="title"
    @action-click="copySowing"
    @close="closeCopySowingModal"
  >
    <template #card-content>
      <div class="df-flex-xl df-flex-col df-copy-modal">
        <slot name="card"></slot>
        <div class="df-flex-sm df-flex-col modal__subheader">
          <h2>{{ $t('SowingsPlanning.copy_sowing_modal.select_field') }}</h2>
          <p>{{ description }}</p>
        </div>
      </div>
    </template>
    <template #outside-content>
      <div class="df-flex-l df-flex-col field__list">
        <div
          v-for="field in fields"
          :key="field.id"
          class="df-flex-l align-center list__item"
          :class="isChecked(field.id)"
          @click="toggleField(field.id)"
        >
          <div
            class="d-flex justify-center align-center item__checkbox"
            :class="isChecked(field.id)"
          >
            <font-awesome-icon v-if="isChecked(field.id)" icon="check" />
          </div>
          <p>{{ field.name }}</p>
        </div>
      </div>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'

export default {
  name: 'DfCopyModal',

  components: {
    DfModal,
  },

  props: {
    description: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      fields: [],
      selectedFieldIds: [],
    }
  },

  created() {
    this.fields = this.getFields()
  },

  inject: {
    getFields: { from: 'getFields' },
  },

  methods: {
    closeCopySowingModal() {
      this.$emit('close')
    },
    copySowing() {
      this.$emit('action-click', this.selectedFieldIds)
    },
    isChecked(fieldId) {
      const intFieldId = Number(fieldId)
      return this.selectedFieldIds.includes(intFieldId) ? 'checked' : ''
    },
    toggleField(fieldId) {
      const intFieldId = Number(fieldId)
      if (this.selectedFieldIds.includes(intFieldId)) {
        this.selectedFieldIds = this.selectedFieldIds.filter(
          (selectedFieldId) => selectedFieldId !== intFieldId
        )
      } else {
        this.selectedFieldIds.push(intFieldId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.df-copy-modal {
  .modal__subheader {
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.field__list {
  flex: 0 1 calc(100% - 343px);
  min-height: 96px;
  padding: 24px;
  overflow-y: auto;
  background-color: #f8f9f8;

  @include d(m) {
    flex: 0 1 calc(100% - 382px);
    min-height: 120px;
  }
  .list__item {
    flex-wrap: nowrap;
    padding: 24px;
    background-color: #ffffff;
    border: 1px solid #e6e9e6;
    border-radius: 8px;
    cursor: pointer;

    &.checked {
      border-color: #39af49;
    }
    p {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .item__checkbox {
      height: 16px;
      width: 16px;
      background-color: #f8f9f8;
      border: 1px solid #aab2a9;
      border-radius: 4px;

      &.checked {
        background-color: #39af49;
        border-color: #39af49;
      }
      svg {
        height: 10px;
        width: 10px;
        color: #ffffff;
      }
    }
  }
}
</style>
